import FrontendService from '@/libs/frontend.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchBanners(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				FrontendService.getBannerLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
        addBanner(ctx, bannerData) {
			return new Promise((resolve, reject) => {
				FrontendService.addBanner(bannerData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		updateBanner(ctx, bannerData) {
			return new Promise((resolve, reject) => {
				FrontendService.updateBanner(bannerData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
        deleteBanner(ctx, bannerData) {
			return new Promise((resolve, reject) => {
				FrontendService.deleteBanner(bannerData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchNotifications(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				FrontendService.getNotificationLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addNotification(ctx, notificationData) {
			return new Promise((resolve, reject) => {
				FrontendService.addNotification(notificationData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
        deleteNotification(ctx, notificationData) {
			return new Promise((resolve, reject) => {
				FrontendService.deleteNotification(notificationData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		fetchPostCategories(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				FrontendService.getPostCategoryLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		addPostCategory(ctx, postCategoryData) {
			return new Promise((resolve, reject) => {
				FrontendService.addPostCategory(postCategoryData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		updatePostCategory(ctx, postCategoryData) {
			return new Promise((resolve, reject) => {
				FrontendService.updatePostCategory(postCategoryData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		deletePostCategory(ctx, postCategoryData) {
			return new Promise((resolve, reject) => {
				FrontendService.deletePostCategory(postCategoryData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		fetchPosts(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				FrontendService.getPostLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		addPost(ctx, postData) {
			return new Promise((resolve, reject) => {
				FrontendService.addPost(postData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		updatePost(ctx, postData) {
			return new Promise((resolve, reject) => {
				FrontendService.updatePost(postData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		deletePost(ctx, postData) {
			return new Promise((resolve, reject) => {
				FrontendService.deletePost(postData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		fetchInformations(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				FrontendService.getInfoLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		addInformation(ctx, informationData) {
			return new Promise((resolve, reject) => {
				FrontendService.addInformation(informationData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		updateInformation(ctx, informationData) {
			return new Promise((resolve, reject) => {
				FrontendService.updateInformation(informationData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		deleteInformation(ctx, informationData) {
			return new Promise((resolve, reject) => {
				FrontendService.deleteInformation(informationData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchContactCategory(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				FrontendService.getContactCategoryLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		addContactCategory(ctx, contactCategoryData) {
			return new Promise((resolve, reject) => {
				FrontendService.addContactCategory(contactCategoryData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		updateContactCategory(ctx, contactCategoryData) {
			return new Promise((resolve, reject) => {
				FrontendService.updateContactCategory(contactCategoryData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		deleteContactCategory(ctx, contactCategoryData) {
			return new Promise((resolve, reject) => {
				FrontendService.deleteContactCategory(contactCategoryData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},

		fetchContact(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				FrontendService.getContactLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
	}
}
