import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function useContactList() {
	const refContactListTable = ref(null)

	const tableColumns = [
		{ key: 'index', label: '#' },
		{ key: 'contact_category_name', label: i18n.t('Contact category') },
		{ key: 'username', label: i18n.t('username') },
		{ key: 'email', label: i18n.t('email') },
		{ key: 'content', label: i18n.t('Content') },
		// { key: 'status', label: i18n.t('Status') },
		{ key: 'created_at', label: i18n.t('Created at') },
		{ key: 'updated_at', label: i18n.t('Updated at') },
		// { key: 'action', label: i18n.t('Actions') },
	]
	const perPage = ref(25)
	const totalContact = ref(0)
	const currentPage = ref(1)
	const contactCategoryName = ref()


	const refetchData = () => {
		refContactListTable.value.refresh()
	}

	watch([currentPage], () => {
		refetchData()
	})

	const fetchContact = (ctx, callback) => {
		store
			.dispatch('frontend/fetchContact', {
				page: currentPage.value,
			})
			.then(response => {
				if (response.data.code == '00') {
					callback(response.data.data)
					totalContact.value = response.data.count
					perPage.value = currentPage.value == 1 ? response.data.data.length : 25
					contactCategoryName.value = response.data.data
				}
			})
	}

	const dataMeta = computed(() => {
		const localItemsCount = refContactListTable.value ? refContactListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalContact.value,
		}
	})

	return {
		fetchContact,
		tableColumns,
		perPage,
		currentPage,
		totalContact,
		dataMeta,
		refContactListTable,
		refetchData,
	}
}
