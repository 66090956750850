/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class FrontendService {

	getBannerLists(queryParams) {
		const pars = {
			pageNumber: queryParams.page,
			type: queryParams.type,
			platform: queryParams.platform,
			status: queryParams.status
		}
		return instance.get('/api/banner/index', { headers: authHeader(), params: pars })
	}
    deleteBanner(bannerData) {
		const FormData = require('form-data')
		const data = new FormData()
        /* if (bannerData.bannerIds.length > 0) {
			for (let i = 0; i < bannerData.bannerIds.length; i++) {
				data.append('bannerIds[]', bannerData.bannerIds[i])
			}
		} */
        data.append('bannerIds[]', bannerData.bannerIds)
		return instance.post('/api/banner/delete', data, { headers: authHeader() })
	}
    addBanner(bannerData) {
		const FormData = require('form-data')
		const data = new FormData()
        data.append('title', bannerData.title)
		data.append('type', bannerData.type)
		data.append('platform', bannerData.platform)
		if (bannerData.link) {
			data.append('link', bannerData.link);
		}
        if (bannerData.banners.length > 0) {
			for (let i = 0; i < bannerData.banners.length; i++) {
				data.append('banners[]', bannerData.banners[i])
			}
		}

		return instance.post('/api/banner/create', data, { headers: authHeader() })
	}

	updateBanner(bannerData) {
		const FormData = require('form-data')
		const data = new FormData()
        data.append('id', bannerData.id)
		data.append('type', bannerData.type)
		data.append('platform', bannerData.platform)
		data.append('status', bannerData.status);
		if (bannerData.link) {
			data.append('link', bannerData.link);
		}

		return instance.post('/api/banner/update', data, { headers: authHeader() })
	}
    getNotificationLists(queryParams) {
		const pars = {
			pageNumber: queryParams.page,
		}
		return instance.get('/api/notification/index', { headers: authHeader(), params: pars })
	}
    deleteNotification(notificationData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('notificationId', notificationData.id)

		return instance.post('/api/notification/delete', data, { headers: authHeader() })
	}
    addNotification(notificationData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('content', notificationData.content)

		return instance.post('/api/notification/create', data, { headers: authHeader() })
	}

	getPostCategoryLists(queryParams) {
		const pars = {
			id: queryParams.id,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/post-category/index', { headers: authHeader(), params: pars })
	}

	addPostCategory(postCategoryData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('name', postCategoryData.name)
		data.append('name_en', postCategoryData.name_en)
		return instance.post('/api/post-category/create', data, { headers: authHeader() })
	}

	updatePostCategory(postCategoryData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('id', postCategoryData.id)
		data.append('name', postCategoryData.name)
		data.append('name_en', postCategoryData.name_en)
		data.append('status', postCategoryData.status)
		return instance.post('/api/post-category/update', data, { headers: authHeader() })
	}

	deletePostCategory(postCategoryData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('id', postCategoryData.id)

		return instance.post('/api/post-category/delete', data, { headers: authHeader() })
	}

	getPostLists(queryParams) {
		const pars = {
			id: queryParams.id,
			category_id: queryParams.category_id,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/post/index', { headers: authHeader(), params: pars })
	}

	addPost(postData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('title', postData.title)
		data.append('title_en', postData.title_en)
		data.append('category_id', postData.category_id)
		data.append('image', postData.image)
		data.append('short_description', postData.short_description)
		data.append('short_description_en', postData.short_description_en)
		data.append('link', postData.link)
		data.append('content_en', postData.content_en)
		data.append('content', postData.content)

		return instance.post('/api/post/create', data, { headers: authHeader() })
	}

	updatePost(postData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('id', postData.id)
		data.append('title', postData.title)
		data.append('title_en', postData.title_en)
		data.append('category_id', postData.category_id)
		data.append('image', postData.image)
		data.append('short_description', postData.short_description)
		data.append('short_description_en', postData.short_description_en)
		data.append('link', postData.link)
		data.append('status', postData.status)
		data.append('content_en', postData.content_en)
		data.append('content', postData.content)
		return instance.post('/api/post/update', data, { headers: authHeader() })
	}

	deletePost(postData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('id', postData.id)

		return instance.post('/api/post/delete', data, { headers: authHeader() })
	}

	getInfoLists(queryParams) {
		const pars = {
			id: queryParams.id,
			status: queryParams.status,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/info/index', { headers: authHeader(), params: pars })
	}

	addInformation(informationData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('name', informationData.name)
		data.append('name_en', informationData.name_en)
		data.append('content', informationData.content)
        if (informationData.banners.length > 0) {
			for (let i = 0; i < informationData.banners.length; i++) {
				data.append('banners[]', informationData.banners[i])
			}
		}
		return instance.post('/api/info/create', data, { headers: authHeader() })
	}

	updateInformation(informationData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('id', informationData.id)
		data.append('name', informationData.name)
		data.append('name_en', informationData.name_en)
		data.append('content', informationData.content)
		data.append('status', informationData.status)
        if (informationData.banners.length > 0) {
			for (let i = 0; i < informationData.banners.length; i++) {
				data.append('banners[]', informationData.banners[i])
			}
		}
		return instance.post('/api/info/update', data, { headers: authHeader() })
	}

	deleteInformation(informationData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('id', informationData.id)

		return instance.post('/api/info/delete', data, { headers: authHeader() })
	}
	getContactCategoryLists(queryParams) {
		const pars = {
			id: queryParams.id,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/contact-category/index', { headers: authHeader(), params: pars })
	}

	addContactCategory(contactCategoryData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('name', contactCategoryData.name)
		data.append('name_en', contactCategoryData.name_en)


		return instance.post('/api/contact-category/create', data, { headers: authHeader() })
	}

	deleteContactCategory(contactCategoryData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('id', contactCategoryData.id)

		return instance.post('/api/contact-category/delete', data, { headers: authHeader() })
	}

	getContactLists(queryParams) {
		const pars = {
			id: queryParams.id,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/contact/index', { headers: authHeader(), params: pars })
	}
}

export default new FrontendService()
